import React, {ReactNode} from 'react';
import './ContactSection.scss';
import {StaticImage} from "gatsby-plugin-image";
import joinClassNames from "../../utils/joinClassNames";
import PhoneLink from "../Link/PhoneLink";
import EmailLink from "../Link/EmailLink";

export const SECTION_ID = 'contact'

const ContactSection = () => {
    return (
        <section className='section contact-section' id={SECTION_ID}>
            <div className="container has-text-centered">
                <h3 className='has-text-primary has-text-weight-semibold is-size-5 is-size-4-desktop mb-3 is-uppercase has-letter-spacing'>Address
                    and</h3>
                <p className='has-text-dark has-text-weight-semibold is-size-3 is-size-2-desktop mb-6'>Contact</p>
                <div className='contact-section__content'>
                    <div className='contact-section__details'>
                        <ContactDetail image={<StaticImage src={'../../images/contact/address.png'} alt={'address'}/>}>
                            <p className='is-size-4 has-text-color-purple has-text-weight-bold'>iTEAMly</p>
                            <p>ul. Armii Krajowej 18</p>
                            <p className='mb-2'>30-150 Kraków</p>
                            <p>KRS: 0000965872</p>
                            <p>NIP: 9452255186</p>
                        </ContactDetail>
                        <div>
                            <ContactDetail image={<StaticImage src={'../../images/contact/email.png'} alt={'email'}/>}
                                           isCentered>
                                <p>
                                    <EmailLink email={'office@iteamly.com'}/>
                                </p>
                            </ContactDetail>
                        </div>
                    </div>
                    <div>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </section>
    );
};

type ContactDetailProps = {
    image: ReactNode
    children: ReactNode
    isCentered?: boolean
}

const ContactDetail = ({image, children, isCentered = false}: ContactDetailProps) => {
    return (
        <div className={joinClassNames('contact-section__detail', isCentered && 'is-centered')}>
            <div className='mr-4' style={{flexShrink: '0'}}>
                {image}
            </div>
            <div className='has-text-left'>
                {children}
            </div>
        </div>
    )
}

const ContactForm = () => {
    return (
        <form className='has-text-right' name="contact" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact"/>
            <p className="is-hidden">
                <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
            </p>
            <div className="field is-horizontal">
                <div className="field-label is-normal is-hidden-mobile">
                    <label className="label">From</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <p className="control is-expanded">
                            <input name={'name'} className="input" type="text" placeholder="Name" required={true}/>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control is-expanded">
                            <input name={'email'} className="input" type="email" placeholder="Email"/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label"/>
                <div className="field-body">
                    <div className="field is-expanded">
                        <p className="control is-expanded">
                            <input name={'phone'} className="input" type="tel" placeholder="Phone number"/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label is-normal is-hidden-mobile">
                    <label className="label">Message</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <textarea name={'message'} className="textarea" placeholder="Leave your message" required={true} minLength={10}/>
                        </div>
                    </div>
                </div>
            </div>
            <button className="button is-primary is-right">
                Send message
            </button>
        </form>
    )
}

export default ContactSection;
